import React, { useEffect, useState } from 'react'
import { useWindowSize } from 'usehooks-ts'

const Navbar = ({ services }) => {

  const [ mobile, setMobile ] = useState(true)
  const [ open, setOpen ] = useState(false)

  const handleScroll = (element, service) => {
    let topElem = document.getElementById(element)
    let top = topElem.offsetTop
    if(service){
      window.scrollTo({ top: top + window.innerHeight, behavior: 'smooth' })
    } else {
      window.scrollTo({ top: top - 90, behavior: 'smooth' })
    }
    if (open && mobile) {
      setOpen(false)
    }
  }
  const { width } = useWindowSize()

  useEffect(() => {
    if (width > 992) {
      setMobile(false)
      setOpen(true)
    } else {
      setMobile(true)
    }
  }, [width])


  return (
    <nav className='nav shadow-xl fixed bg-white w-full z-20 top-0'>
      <div className='nav__content flex items-center justify-between relative py-4 lg:py-0'>
        <div className='nav__content--brand'>
          <img
            className='max-w-full w-[120px] lg:w-[170px] 2xl:w-[200px] cursor-pointer'
            src='../images/logo-465-consultora-de-negocios.svg'
            alt='465 Consultora de Negocios'
            onClick={() => handleScroll('main')}
          />
        </div>
        {
          open ? (
            <>
              <ul className='nav__content--items items flex flex-col lg:flex-row absolute -left-4 bg-blue lg:bg-white w-full lg:w-auto lg:relative'>
              <li className='items__link items__link--external py-2 my-auto'>
                  <a className='' href='https://app.465analytics.com/' target='_blank' rel='noreferrer'>
                    465 Analytics <i className="fas fa-external-link-alt"></i>
                  </a>
                </li>
                <li className='items__link relative py-4 lg:py-9 text-white'>
                  Nuestros Servicios
                  <ul className='items__link--subitems lg:absolute bg-blue-700 text-white w-[300px]'>
                    {
                      services.length > 0 && services.map((service, index) => (
                        <li
                          className='pr-8 pl-5 py-3 normal-case'
                          key={index}
                          onClick={() => handleScroll(service.slug, true)}
                        >
                          <span className='text-blue-900 f-black pr-3 inline-block'>{`0${index + 1}`}</span>
                          {service.name}
                        </li>
                      ))
                    }
                  </ul>
                </li>
                <li className='items__link relative py-4 lg:py-9 text-white' onClick={() => handleScroll('about')}>
                  Quiénes somos
                </li>
                <li className='items__link relative py-4 lg:py-9 text-white' onClick={() => handleScroll('team')}>
                  Equipo
                </li>
                {/* <li className='items__link items__link--external py-2 my-auto'>
                  <a className='' href='https://465consultora.bitrix24.es/' target='_blank' rel='noreferrer'>
                    Autogestión <i className="fas fa-external-link-alt"></i>
                  </a>
                </li> */}
                
                {/* <li className='items__link py-4 lg:py-9'>
                  <a className='' href='https://wa.link/gcs8n5' target='_blank' rel='noreferrer'>
                    <i className="fa fa-whatsapp fa-2x"></i>
                  </a>
                </li> */}
              </ul>
              { 
                mobile && <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24" onClick={() => setOpen(false)}>
                  <path className="inline-flex" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              }
              </>
          ) : (
            mobile && <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24" onClick={() => setOpen(true)}>
              <path className="inline-flex" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          )
        }
      </div>
    </nav>
  )
}

export default Navbar